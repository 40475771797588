import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
// import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import ControlPanel from "../../components/ControlPanel/new"
import ControlPanelMobile from "../../components/ControlPanel/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import Configurador from "../../components/Configurador/"

// ASSETS
import ElectramVideo from "../../assets/videos/Electram/electram.mp4"
import ElectramVideoPreview from "../../assets/videos/Electram/electram-preview.mp4"
import ElectramDetailVideo from "../../assets/videos/Electram/electram-detail-1.mp4"
import ElectramDetailVideoMobile from "../../assets/videos/Electram/mobile/electram-video-detail-1.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  var config = true
  if(activeLanguage === 'br' || activeLanguage === 'mx' ){
    config = false
  }
  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={React.useContext(I18nextContext).language}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={ElectramVideo}
        src={ElectramVideoPreview}
        poster="https://ramalhos.com/video_posters/Electram/electram.jpg"
        width="100%"
        id="video-electram"
        imgMobile={t('video', { returnObjects: true })['bgMobile']}
        fullString={t('videoHome', { returnObjects: true })['info']}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t('introduction', { returnObjects: true })}
        lang={activeLanguage}
        link={"../../config-electram"}
        configurador
      />

      {!breakpoints.tl && ( // Desktop
        <>
          <Carousel
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-electram"
            carouselContent={t('carousel', { returnObjects: true })}
          />
          <VideoDetail
            src={ElectramDetailVideo}
            poster="https://ramalhos.com/video_posters/Electram/electram-detail-1.jpg"
            id="video-detail-electram"
            _title={t('video', { returnObjects: true })['title1']}
            text1={t('video', { returnObjects: true })['text1_1']}
            text2={t('video', { returnObjects: true })['text1_2']}
            titleWidth="700px"
            textWidth="600px"
          />
          <FeatureLine
            id="feature-electram-1"
            _title={t('feature', { returnObjects: true })['title1']}
            text={t('feature', { returnObjects: true })['text1']}
            image={t('feature', { returnObjects: true })['image1_1']}
            positionCenter
            midBrightness
          />
          <FeatureLine
            id="feature-electram-2"
            _title={t('feature', { returnObjects: true })['title2']}
            text={t('feature', { returnObjects: true })['text2']}
            image={t('feature', { returnObjects: true })['image2_1']}
            titleWidth="500px"
            growUp
          />
          <FeatureLine
            id="feature-electram-3"
            _title={t('feature', { returnObjects: true })['title3']}
            text={t('feature', { returnObjects: true })['text3']}
            positionCenter
          />
          <ControlPanel
            id="control-panel-electram"
            carouselContent={t('panel', { returnObjects: true })['panels']}
            _title={{ title1: t('panel', { returnObjects: true })['title1'], title2: t('panel', { returnObjects: true })['title2'] }}
            defaultImage={t('panel', { returnObjects: true })['panels']['default']}
          />
          <FeatureLine
            id="feature-electram-4"
            _title={t('feature', { returnObjects: true })['title4']}
            image={t('feature', { returnObjects: true })['image4_1']}
            titleWidth="500px"
            marginLeft="700px"
          />
        </>
      )}

      {/* --- */}

      {breakpoints.tl && ( // Mobile
        <>
          <CarouselMobile
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-electram-mobile"
            carouselContent={t('carousel', { returnObjects: true })}
          />
          <VideoDetailMobile
            src={ElectramDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/Electram/mobile/electram-video-detail-1.jpg"
            id="video-detail-electram-mobile"
            _title={t('video', { returnObjects: true })['title1']}
            text={t('video', { returnObjects: true })['text1_1']}
            text2={t('video', { returnObjects: true })['text1_2']}
            positionAbsolute
          />
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t('feature', { returnObjects: true })['title1']}
            text1={t('feature', { returnObjects: true })['text1']}
            image={t('feature', { returnObjects: true })['image1_2']}
            positionCenter
          />
          <FeatureLineMobile
            id="second-line-mobile"
            _title={t('feature', { returnObjects: true })['title2']}
            text1={t('feature', { returnObjects: true })['text2']}
            image={t('feature', { returnObjects: true })['image2_2']}
            positionTopBottom
          />
          <ControlPanelMobile
            id="control-panel-mobile-electram"
            _title={{ title1: t('panel', { returnObjects: true })['title1'], title2: t('panel', { returnObjects: true })['title2'] }}
            carouselContent={t('panel', { returnObjects: true })['panels']}
          />
        </>
      )}

      {/* --- */}

      {config && <Configurador id="electram-initial" content={t("configurador", { returnObjects: true })}/>}

      {/* <SpecsTable
        id="specs-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="230px"
      /> */}

        {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryElectramPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "electram", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
